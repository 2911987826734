<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types'
import { startAuthentication } from '@simplewebauthn/browser'
import { toast } from 'vue-sonner'
import { z } from 'zod'

const { fetch: refreshSession } = useUserSession()

const passkeySchema = z.object({
  email: z.string().email('Email inválido'),
})

const state = reactive({
  email: undefined,
})

const loading = ref(false)

async function getAuthenticationOptionsJSON(email) {
  const options = await $fetch('/auth/passkey/login/create-key', {
    method: 'POST',
    body: {
      email,
    },
  })
  return options
}

async function loginUserWithPasskey(challenge, email, authenticationResponse) {
  const user = await $fetch('/auth/passkey/login/login-user', {
    method: 'POST',
    body: {
      email,
      challenge,
      authenticationResponse,
    },
  })
  return user
}

async function onSubmit(event: FormSubmitEvent<typeof state>) {
  try {
    loading.value = true
    const authenticationOptionsJSON = await getAuthenticationOptionsJSON(event.data.email)
    const authenticationResponse = await startAuthentication(authenticationOptionsJSON)
    await loginUserWithPasskey(
      authenticationOptionsJSON.challenge,
      event.data.email,
      authenticationResponse,
    )
    await refreshSession()
    toast.success('Sesion iniciada')
    return navigateTo(`/dashboard`)
  }
  catch (error) {
    console.error(error)
    toast.error(error.data?.message ?? 'Ocurrió un error')
  }
  finally {
    loading.value = false
  }
}

// WORKAROUND: UInput variant set to 'outline' on errored state which
// inherits 'bg-transparent' so we need to override to preserve colors
const UI_INPUT = { variant: { outline: 'bg-white dark:bg-gray-900' } }
</script>

<template>
  <UForm
    :schema="passkeySchema"
    :state="state"
    class="space-y-2 max-w-sm"
    @submit="onSubmit"
  >
    <UFormGroup
      label="Email"
      name="email"
      size="lg"
    >
      <UInput
        v-model="state.email"
        autocomplete="email"
        :ui="UI_INPUT"
      />
    </UFormGroup>
    <UButton
      color="primary"
      type="submit"
      size="lg"
      block
      icon="i-mdi-fingerprint"
      :loading="loading"
      :disabled="!regex.email.test(state.email!) || loading"
      class="!mt-4"
    >
      Inicia sesión con passkey
    </UButton>
  </UForm>
</template>
