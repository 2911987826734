<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types'
import { reactive, ref } from 'vue'
import { toast } from 'vue-sonner'
import { z } from 'zod'

const otpSchema = z.object({
  email: z.string().email('Email inválido'),
})

const passwordSchema = z.object({
  email: z.string().email('Email inválido'),
  password: z.string().min(8, 'Tiene que tener al menos 8 caracteres'),
})

const schema = z.union([otpSchema, passwordSchema])

const { fetch: refreshSession } = useUserSession()

const state = reactive({
  email: undefined,
  password: undefined,
})

const loading = ref(false)

async function onSubmitPassword(event: FormSubmitEvent<typeof state>) {
  try {
    loading.value = true
    await $fetch('/auth/login-with-password', {
      method: 'POST',
      body: {
        email: event.data.email,
        password: event.data.password,
      },
    })
    await refreshSession()
    toast('Sesión iniciada')
    navigateTo('/dashboard')
  }
  catch (error) {
    loading.value = false
    toast.error(error.data.message)
  }
}

async function onSubmitOTP(event: FormSubmitEvent<typeof state>) {
  try {
    loading.value = true
    await $fetch('/auth/login-with-otp', {
      method: 'POST',
      body: {
        email: event.data.email,
        type: 'login',
      },
    })
    toast('Código OTP enviado a tu email')
    navigateTo(`/auth/verify-otp?email=${encodeURIComponent(event.data.email!)}&type=login`)
  }
  catch (error) {
    loading.value = false
    toast.error(error.data.message)
  }
}

async function onSubmit(event: FormSubmitEvent<typeof state>) {
  if (event.data.password) return onSubmitPassword(event)
  return onSubmitOTP(event)
}

// WORKAROUND: UInput variant set to 'outline' on errored state which
// inherits 'bg-transparent' so we need to override to preserve colors
const UI_INPUT = { variant: { outline: 'bg-white dark:bg-gray-900' } }
</script>

<template>
  <UForm
    :schema="state.password ? passwordSchema : otpSchema"
    :state="state"
    class="space-y-2 max-w-sm"
    @submit="onSubmit"
  >
    <UFormGroup
      label="Email"
      name="email"
      size="lg"
    >
      <UInput
        v-model="state.email"
        autocomplete="email"
        :ui="UI_INPUT"
      />
    </UFormGroup>
    <UFormGroup
      label="Contraseña"
      name="password"
      size="lg"
    >
      <UInput
        v-model="state.password"
        type="password"
        autocomplete="current-password"
        :placeholder="state.password ? undefined : 'Opcional'"
        :ui="UI_INPUT"
      />
      <template #hint>
        <UButton
          variant="link"
          :padded="false"
          to="/auth/forgot-password"
          size="xs"
          color="gray"
        >
          ¿Olvidaste tu contraseña?
        </UButton>
      </template>
    </UFormGroup>
    <UButton
      color="primary"
      type="submit"
      size="lg"
      block
      :icon="state.password ? undefined : 'i-mdi-email'"
      :loading="loading"
      :disabled="!regex.email.test(state.email!) || loading"
      class="!mt-4"
    >
      {{ state.password ? 'Iniciar sesión' : 'Enviar link de acceso' }}
    </UButton>
  </UForm>
</template>
