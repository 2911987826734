<script setup lang="ts">
definePageMeta({ layout: 'auth' })

const seo = { title: 'Iniciar sesión' }

useSeoMeta({
  titleTemplate: '%s · Netzo',
  title: () => seo.title,
  ogTitle: () => seo.title,
})

const appConfig = useAppConfig()

const tabItems = [
  { label: 'Passkey', value: 'passkey', icon: 'i-mdi-fingerprint' },
  { label: 'Contraseña', value: 'password', icon: 'i-mdi-form-textbox-password' },
]

const tab = ref(0)
const mode = ref(tabItems[tab.value]?.value)

// TODO: push errors to query for better UX with feedback
const error = useRoute().query.error as string
</script>

<template>
  <UCard class="max-w-sm w-full bg-white/75 dark:bg-white/5 backdrop-blur">
    <div class="w-full max-w-sm mx-auto space-y-6">
      <h1 class="font-semibold text-2xl text-center">
        {{ seo.title }}
      </h1>

      <UAlert
        v-if="error"
        :title="error"
        variant="subtle"
        color="red"
        icon="i-mdi-alert-circle"
        class="mt-4 text-left"
      />

      <UTabs
        v-model="tab"
        :items="tabItems"
        :ui="{ list: { background: '' } }"
        @update:model-value="mode = tabItems[$event].value"
      />

      <AuthLoginPasswordForm v-if="mode === 'password'" />
      <AuthLoginPasskeyForm v-if="mode === 'passkey'" />

      <template v-if="appConfig.netzo?.auth?.oauth?.length">
        <UDivider label="o" />
        <AuthSocialLogin :providers="appConfig.netzo?.auth?.oauth" />
      </template>
    </div>

    <template #footer>
      <p class="text-sm text-center">
        ¿No tienes una cuenta?
        <UButton
          to="/auth/register"
          variant="link"
          :padded="false"
          color="gray"
        >
          Registrarse
        </UButton>
      </p>
    </template>
  </UCard>
</template>
